import React from 'react';

import SectionBlock from '../components/Block/NewSectionBlock';
import SectionCta from '../components/Block/SectionCta';
import CaseStudiesList from '../components/CaseStudy/CaseStudiesList';

import './digital-transformation.scss';

const DigitalTransformationPage = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Digital transformation</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SectionBlock
      title="Digital Transformation at SolveQ"
      image={{ fullSize: true, url: '/pillars/transformations_aside.png' }}
    >
      <p>Stay ahead of the competition by aligning people, data, and technology.</p>
      <p>
        A Rapidly Changing World Needs Rapidly Changing Solutions. Digital Transformation helps you to generate new
        possibilities, extend the profit by better services and faster adaptation to a changing World.
      </p>
      <p>
        We help you to achieve this by migrating your legacy applications and systems in time-effective Continuous
        Integration and Continuous Delivery methods.
      </p>
      <p>
        Use your data to build new Digital Business Models improving process visibility, transparency, and providing
        better information and visibility to customers.
      </p>
      <p>
        Digital Transformation is the key to transform your organization to better align with the business changes and
        customer’s expectations. We have already proved this by implementing new models in logistics, Freight
        forwarding, and telecom industries.
      </p>
    </SectionBlock>
    <CaseStudiesList caseStudyTags={['transformation']} />
    <SectionBlock
      title="How we can help you"
      image={{ url: '/pillars/transformation_main.png' }}
      cta={{ url: '/contact', text: 'Contact us' }}
    >
      <h4>Existing business process analysis</h4>
      <p>
        Let’s sit down together, try to understand the pain points and new requirements and we will let you know what
        would be the most effective way to implement changes.
      </p>
      <h4>Improvements proposal, choice of technology stack</h4>
      <p>
        We value your ideas and voice, but we will let you know our vision, doubts, and challenges. All for the sake of
        building the best product in the world.
      </p>
      <h4>Implementation</h4>
      <p>
        Implementation This is what we love the most. Working on the code, building new features, and showing our
        progress to you regularly.
      </p>
      <h4>Maintenance</h4>
      <p>
        Maintenance We continue to help you develop new features and product parts, we will analyze potential issues,
        user feedback. We will not let you stay alone.
      </p>
    </SectionBlock>
  </>
);

export default DigitalTransformationPage;
