import React from 'react';
import { Link } from 'gatsby';

import '../../styles/theme-text.scss';
import './SectionCta.scss';

const SectionCta = ({ title = false, description = false, btn = { link: '/', text: 'Home' }, customClass = '' }) => {
  const internal = /^\/(?!\/)/.test(btn.link);
  return (
    <section className={`th-section-cta${customClass ? ` ${customClass}` : ''}`}>
      <div className="container-lg">
        <div className="row th-service-section__row">
          <div className={`${title && description ? 'col-md-7' : 'd-none'}`}>
            <h2
              className="th-theme-title-small th-section-title-large"
              style={{ color: '#3D4653', fontSize: 34, fontWeight: 600 }}
            >
              The way we work
            </h2>
            <h2
              className="th-theme-title-large th-section-title-large"
              style={{ fontSize: 22, fontWeight: 400, textTransform: 'none' }}
            >
              Our work is based on three pillars:
              <ul>
                <li>Agility</li> <li>Effective Remote Cooperation</li> <li>Focus on Results</li>
              </ul>
            </h2>
          </div>
          <div className={`${title && description ? 'col-md-5' : 'col-md-12'}`}>
            <div className={`th-service-title-button`}>
              {internal ? <Link to={btn.link}>{btn.text}</Link> : <a href={btn.link}>{btn.text}</a>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionCta;
